import * as React from 'react';
import { getUser } from '@/api';
import { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ContentBlock, Loader, Typography, Header } from '@/components';
import { Grid, View } from '@aws-amplify/ui-react';
import * as QRCode from 'qrcode.react';
import { Auth } from 'aws-amplify';
import { useStyles } from './styles';
import {
  AlertContext,
  AssetsContext,
  InIframeContext
} from '@/GlobalProvider/GlobalProvider';
import {
  formatDateWithTime,
  formatPhoneNumber,
  getFilteredScans,
  getSuccessfulScan,
  useIframeMessageHandler
} from '@/utils';
import { ScanStatus } from '@/constants';

export const Customer = () => {
  const { setAlert } = useContext(AlertContext);
  const inIframe = useContext(InIframeContext);
  const {
    behavior_list: { configurator_url, configurator_eventid_lookup_list }
  } = useContext(AssetsContext);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [previousScan, setPreviousScan] = useState(null);
  const [successfulScan, setSuccessfulScan] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const styles = useStyles();
  const navigate = useNavigate();

  const [shopLink, setShopLink] = useState(
    'https://instore.mtm.uat.gamma.destinationxl.com/mobile-entry?email={emailAddress}&scan_id={scanId}&user_id={userid}&event_id={eventId}'
  );

  const getScans = useCallback(async (customerData: any) => {
    setRefreshLoading(true);
    const filteredScansData = await getFilteredScans(params.customerId);
    const successfulScanData = getSuccessfulScan(filteredScansData);

    if (filteredScansData.length > 0) {
      setPreviousScan(filteredScansData[0]);
    }

    if (shopLink) {
      if (successfulScanData) {
        setSuccessfulScan(successfulScanData);
        const user = await Auth.currentUserInfo();
        const email = user.attributes.email;
        const eventId = configurator_eventid_lookup_list[email];

        setShopLink(
          shopLink
            .replace('{emailAddress}', encodeURIComponent(customerData.email))
            .replace('{scanId}', filteredScansData[0].id)
            .replace('{userid}', customerData.id)
            .replace('{eventId}', eventId)
        );
      } else {
        setShopLink('disabled');
      }
    }
    setRefreshLoading(false);
  }, []);

  useIframeMessageHandler(currentCustomer, successfulScan?.id);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const customerData = await getUser(params.customerId);
      if (customerData.status) {
        if (customerData.status === 403) {
          setAlert({
            text: 'This account is not sharing to your company.',
            variation: 'error'
          });
        } else if (customerData.status === 404) {
          setAlert({
            text: 'This account was not found.',
            variation: 'error'
          });
        }
      } else {
        await getScans(customerData);
        setCurrentCustomer(customerData);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    let interval;

    if (!loading) {
      interval = setInterval(() => {
        getScans(currentCustomer);
      }, 60000);
    }

    return () => clearInterval(interval);
  }, [loading]);

  if (loading) {
    return <Loader />;
  }

  if (!currentCustomer) return null;

  return (
    <>
      <Header />
      <ContentBlock>
        <Grid
          templateColumns="1fr 1fr"
          columnGap="3rem"
          className={styles.customer}
        >
          <View columnSpan="1" className={styles.leftBlock}>
            <div className={styles.qrCodeBlock}>
              <QRCode id="qrCode" value={window.btoa(currentCustomer.id)} />
            </div>
          </View>
          <View columnSpan={[2, 2, 1]} className={styles.content}>
            <Typography component="h2" className={styles.title}>
              Welcome, <br className={styles.br} /> {currentCustomer.firstName}{' '}
              {currentCustomer.lastName}
            </Typography>
            <div>
              <Button
                isDisabled={refreshLoading}
                text={refreshLoading ? 'Loading' : 'Refresh'}
                buttonType="secondary"
                className={styles.refresh}
                onClick={() => getScans(currentCustomer)}
              />
            </div>
            <div className={styles.personalInfo}>
              <Typography component="p" className={styles.subtitle}>
                <span>Phone:</span>{' '}
                {currentCustomer.phone
                  ? formatPhoneNumber(currentCustomer.phone)
                  : '-'}
              </Typography>
              <Typography component="p" className={styles.subtitle}>
                <span>Email:</span> {currentCustomer.email || '-'}
              </Typography>
            </div>
            <div className={styles.actionBlock}>
              <Button
                text="Update Profile"
                buttonType="dark"
                className={styles.button}
                onClick={() => navigate(`/createCustomer/${params.customerId}`)}
              />
              <Button
                text="Scan History"
                buttonType="dark"
                className={styles.button}
                onClick={() =>
                  navigate(`/customers/scans/${params.customerId}`)
                }
              />
            </div>
            {previousScan && (
              <div className={styles.previousScan}>
                <Typography component="p" className={styles.scanBlockTitle}>
                  <span>Previous Scan Details:</span>
                </Typography>
                <div className={styles.scan}>
                  <Typography component="p" className={styles.scanDetails}>
                    <span>Date:</span> {formatDateWithTime(previousScan.date)}
                  </Typography>
                  <Typography component="p" className={styles.scanDetails}>
                    <span>Status:</span>{' '}
                    {previousScan.status === ScanStatus.Complete
                      ? 'Complete'
                      : previousScan.status === ScanStatus.Failed
                      ? 'Failed'
                      : previousScan.status === ScanStatus.WaitingForData
                      ? 'Waiting for Data'
                      : 'Processing'}
                  </Typography>
                </div>
              </div>
            )}
            <div className={styles.qrCodeBlockMobile}>
              <QRCode id="qrCode" value={window.btoa(currentCustomer.id)} />
            </div>
          </View>
        </Grid>
        <div className={styles.buttonsContainer}>
          <div className={styles.backButton}>
            <Button
              buttonType="dark"
              text={
                inIframe ? 'Select a different customer' : 'Back to Dashboard'
              }
              onClick={() => navigate('/')}
            />
          </div>
          {shopLink && !inIframe && (
            <div className={styles.continueShoppingButton}>
              <Button
                buttonType="secondary"
                isDisabled={shopLink === 'disabled'}
                text="Shop Made to Measure"
                onClick={() => window.open(shopLink)}
              />
            </div>
          )}
        </div>
      </ContentBlock>
    </>
  );
};
